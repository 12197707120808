<template>
  <el-container class="patroldetail container">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>巡查管理</el-breadcrumb-item>
        <el-breadcrumb-item>巡查列表</el-breadcrumb-item>
        <el-breadcrumb-item>巡查记录</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()"
          >返回</el-button
        >
      </div>
    </el-header>
    <el-main class="p15">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="巡查人姓名"
              clearable
              @clear="onSubmit"
              v-model="page.patrol_user_name"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="page.patrol_state"
              clearable
              placeholder="巡查状态"
              @change="onSubmit(page.patrol_state)"
            >
              <el-option key="" label="请选择巡查状态" value=""></el-option>
              <el-option key="1" label="未巡查" value="1"></el-option>
              <el-option key="2" label="已巡查" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              type="date"
              clearable
              placeholder="巡查日期"
              v-model="page.patrol_time"
              :picker-options="pickerOptionsEnd"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="onSubmit(page.patrol_time)"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="PatroleAdd">新增巡查</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search">
       <el-form :inline="true" :model="page" class="demo-form-inline">
         <el-col :span="4" class="mr10">
             <el-form-item size="medium" >
         <el-input placeholder="巡查人姓名" clearable @clear='onSubmit' v-model="page.patrol_user_name" class="input-with-select">
         </el-input>
        </el-form-item>
        </el-col>
         <el-col :span="4" class="mr10">
         <el-form-item size="medium">
          <el-select v-model="page.patrol_state" clearable placeholder="巡查状态" @change="onSubmit(page.patrol_state)"> 
            <el-option key="" label="请选择巡查状态" value=""></el-option>
            <el-option key="1" label="未巡查" value="1"></el-option>
            <el-option key="2" label="已巡查" value="2"></el-option>
          </el-select>
        </el-form-item>
        </el-col>
         <el-col :span="4" class="mr10">
          <el-form-item size="medium">
             <el-date-picker type="date" clearable placeholder="巡查日期" v-model="page.patrol_time"  :picker-options="pickerOptionsEnd" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="onSubmit(page.patrol_time)"></el-date-picker>
        </el-form-item>
        </el-col>
        <el-col :span="2" class="tl">
           <el-form-item  size="medium">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
        </el-form-item>
        </el-col>
        <el-col class="fr  tr" :span="3"><el-button type="primary" size="medium" @click="PatroleAdd">新增巡查</el-button></el-col>
      </el-form>
    </el-row> -->
      <div class="box list">
        <el-table border :data="list1" style="width: 100%">
          <el-table-column
            type="index"
            align="center"
            width="80"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="work_order"
            min-width="120"
            :show-overflow-tooltip="true"
            label="工单号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="patrol_user_name"
            min-width="120"
            :show-overflow-tooltip="true"
            align="center"
            label="巡查人"
          ></el-table-column>
          <el-table-column
            min-width="120"
            :show-overflow-tooltip="true"
            align="center"
            label="巡查结果"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.patrol_result == '不合格'" class="red"
                >不合格</span
              >
              <span v-else-if="scope.row.patrol_result == '合格'">合格</span>
              <span v-else>---</span>
            </template>
          </el-table-column>
          <el-table-column
            label="处理方式"
            min-width="120"
            align="center"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div :class="scope.row.dispose_name ? 'danger' : ''">
                {{
                  scope.row.dispose_name == "" ? "无" : scope.row.dispose_name
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="patrol_time"
            min-width="120"
            :show-overflow-tooltip="true"
            align="center"
            label="巡查时间"
          ></el-table-column>
          <el-table-column
            label="处罚方式"
            min-width="120"
            align="center"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div :class="scope.row.dispose_name ? 'danger' : ''">
                {{ scope.row.punish_name == "" ? "无" : scope.row.punish_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                class="general_color"
                size="medium"
                @click="godetail(scope.row.patrol_id)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
    <!-- 
      -->
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local, session } from "../../../util/util";
export default {
  data() {
    return {
      activeName: "",
      list: [],
      list1: [],
      region_code: "",
      page: {
        pager_offset: "0",
        patrol_time: "",
        patrol_state: "",
        patrol_type: "1",
        patrol_user_name: "",
        patrol_id: "",
      },
      company_id: "",
      company_name: "",
      currentPage: 1,
      total: 0,
      list_btn3: [
        { id: "1", title: "合格" },
        { id: "2", title: "不合格" },
      ],
      user: "",
      pickerOptionsEnd: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.user = local.get("user");
    this.company_id = session.get("params").id
      ? String(session.get("params").id)
      : "";
    this.company_name = session.get("params").name;
    this.init1(this.page);
  },
  methods: {
    init1(params) {
      axios.get("/pc/patrol/list", params).then((v) => {
        this.list1 = v.data.patrol_list;
        this.total = v.data.pager_count;
      });
    },
    handleClick() {},
    PatroleAdd() {
      session.set("params1", {
        id: 1,
        id1: this.company_id,
        id2: "",
        name: this.company_name,
      });
      this.$router.push({
        name: "PatrolAdd",
        query: {
          type: 2,
        },
      });
    },
    godetail(index) {
      session.set("params1", { id: index, name: "" });
      this.$router.push({
        name: "AddDetail",
        query: {
          type: 2,
        },
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.init1(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.init1(this.page);
    },
  },
};
</script>
<style lang="scss" scoped>
.patroldetail {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image {
    width: 160px;
    height: 160px;
    margin-right: 10px;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .patroldetail-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
}
</style>
